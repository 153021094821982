<template>
    <div class="container">
        <h1 class="text-center">{{ $t('title') }}</h1>
        
        
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 text-center">
                </div>
                <div class="col-12 c-card-wrap">
                    <b-card v-if="!id">
                        <h1 class="text-center">{{ $t('passwordReset.title') }}</h1>

                        <p v-if="data!=''">
                            {{data.message}}
                        </p>
                        <template v-else>
                            <p>{{ $t('passwordReset.message') }}</p>
                            <b-form class="login" autocomplete=”off” @submit.prevent="reset">
                                <b-form-group
                                    label-for="email">
                                    <b-form-input
                                        v-model="email"
                                        id="email"
                                        placeholder="E-mail"
                                        required
                                        type="email"
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group>
                                    <b-button class="col-12 " type="submit" variant="primary">{{ $t('passwordReset.reset') }}</b-button>
                                </b-form-group>
                            </b-form>
                        </template>
                    </b-card>
                    <b-card v-else>
                        <h1 class="text-center">{{ $t('passwordReset.title') }}</h1>
    
                        <div v-if="data">
                            {{data.message}}
                            <router-link :to="{name: 'homepage'}" class=""><b>{{ $t('passwordReset.login') }}</b></router-link>
                        </div>
                        <b-form v-else class="login" autocomplete=”off” @submit.prevent="updatePassword">
                            
                            <b-form-group
                                label-for="password">
                                <b-form-input
                                    autocomplete="new-password"
                                    v-model="password"
                                    id="password"
                                    :placeholder="$t('passwordReset.passwd')"
                                    required
                                    type="password"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                label-for="password_test">
                                <b-form-input
                                    v-model="password_test"
                                    id="password_test"
                                    :placeholder="$t('passwordReset.passwdTest')"
                                    required
                                    type="password"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-button class="col-12 " type="submit" variant="primary">{{ $t('passwordReset.change') }}</b-button>
                            </b-form-group>
                        </b-form>
                    </b-card>
                </div>
            </div>
        </div>
    
    
    </div>
</template>

<script>
export default {
    name: "password-reset",
    data() {
        return {
            id: this.$route.params.id,
            email: '',
            data: '',
            password: '',
            password_test: ''
        }
    },
    methods: {
        async reset() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/users/password-reset.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {'email': this.email},
                    method: 'PUT'
                });
                
                this.data = response.data;
                //this.$eventHub.$emit('openMessageController', 'success', this.data.message);
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message)
            }
        },
        async updatePassword() {
            if (this.password !== this.password_test) {
                this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.pswd_not_same'))
                return false;
            }
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/users/password-reset-update.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {'hash': this.id, 'password' : this.password, 'password_test': this.password_test },
                    method: 'PUT'
                });
        
                this.data = response.data;
                //this.$eventHub.$emit('openMessageController', 'success', this.data.message);
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message)
            }
        }
    },
    mounted() {
    },
}
</script>

<style scoped>

</style>
